
let DataPage = {
    "faq": [],
    "site": [],
    "brands": [],
    "banner": [],
    "product": [],
    "settings": {},
    "category": [],
    "services": [],
    "schedule": [],
    "slide_show": [],
    "access_site": [],
    "access_dash": []
};

let NotifyDataPage = {
    "faq": [],
    "site": [],
    "brands": [],
    "banner": [],
    "product": [],
    "settings": [],
    "category": [],
    "services": [],
    "schedule": [],
    "slide_show": [],
    "access_site": [],
    "access_dash": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["faq"].forEach(element => { element(value['faq']); });
    NotifyDataPage["site"].forEach(element => { element(value['site']); });
    NotifyDataPage["brands"].forEach(element => { element(value['brands']); });
    NotifyDataPage["banner"].forEach(element => { element(value['banner']); });
    NotifyDataPage["product"].forEach(element => { element(value['product']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["category"].forEach(element => { element(value['category']); });
    NotifyDataPage["services"].forEach(element => { element(value['services']); });
    NotifyDataPage["schedule"].forEach(element => { element(value['schedule']); });
    NotifyDataPage["slide_show"].forEach(element => { element(value['slide_show']); });
    NotifyDataPage["access_site"].forEach(element => { element(value['access_site']); });
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}