import { useState, useEffect } from "react";

import Login from "templates/Login";
import Recover from "templates/Recover";
import Contents from "templates/Contents";

import PopUP_Faq from "components/PopUp/Faq";
import PopUp_Color from "components/PopUp/Color";
import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_Category from "components/PopUp/Category";
import PopUP_Schedule from "components/PopUp/Schedule";
import PopUP_AddOrEditImg from "components/PopUp/AddOrEditImg";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Site } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function App(props) {

    const [ loading, setLoading ] = useState(false); 
    const [ typePage, setTypePage ] = useState(GetListPag('page'));
    const [ url, setUrl ] = useState(window.location.href.split("/"));

    function OpenFile(type, file){
        if(file !=''){
            SetModalData('ShowFile', { "type": type, "file": file });
            SetModalState('ShowFile', true);
        }
    }

    function CallbackSuccess(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CheckedPage(){
        if(url[3] === '?recover=recover'){
            return <Recover setLoading={ setLoading } code={ url[4] } setUrl={ setUrl } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />;

        }else {
            switch(typePage){
                case "dashboard":
                    return <Contents setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } OpenFile={ OpenFile } />;
                default:   
                    return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />;
            }
        }
    }        

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
    }, []);

    return(
        <>
            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            <div className="div_site" style={ { background: colorIcon } }>
                <a href="https://clicksite-preview.fastevolution.com.br/" target="_blank" rel="noreferrer noopener nofollow">
                    <Svg_Site className="icons" color="#ffffff" />
                </a>
            </div>

            {
                CheckedPage()
            }

            <PopUp_Color setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            
            <PopUP_Schedule OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />

            <PopUP_Category OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_AddOrEditImg OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_Faq setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            
            <PopUP_Confirmation setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_ReturnResponse setLoading={ setLoading }/>
            <PopUP_ShowFile setLoading={ setLoading } />
        </>
    );
}