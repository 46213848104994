import './InputFile.css';

import { useEffect, useState } from 'react';

import { colorIcon } from 'fixedData';

import { Svg_Delete, Svg_Img } from 'services/SvgFile';

export default function Comp_InputFile(props){

    const [ file, setFile ] = useState(props.value);

    function RegisterData(value){
        if(value){
            if(props.index === "not"){
                props.setValue(value);
                props.setStatus(true);
            }else {
                props.setValue('status', props.index, true);
                props.setValue('file', props.index, value);
            }
        }else {
            if(props.index === "not"){
                props.setValue('');
                props.setStatus(false);
            }else {
                props.setValue('status', props.index, false);
                props.setValue('file', props.index, '');
            }
        }
    }

    useEffect(()=>{
        setFile(props.value);
    }, [props.value]);

    return(
        <div className="Comp_InputFile">
            <label className={ props.status ? "label_active" : props.value ? "show_file_" : "" }>
                <input type="file" onChange={ (e)=>{ RegisterData(e.target.files[0]) } } />

                <div className="title">
                    {
                        props.status ? "Foto adicionado" : "Adicionar foto"
                    }
                </div>
            </label>
            {
                props.value ? 
                <>                    
                    <div className="div_remuve" onClick={ ()=>{ RegisterData(''); } }>
                        <Svg_Delete className="icons" color="#ffffff" />
                    </div>
                    <div className="show_img" onClick={ ()=>{ props.OpenFile('img', props.status ? URL.createObjectURL(file) : props.value) } }>
                        {
                            props.status ?
                            <img alt="img_" src={ URL.createObjectURL(props.value) } className="img_thumbnail" />
                            :
                            <img alt="img_" src={ props.thumbnail } className="img_thumbnail" />
                        }
                    </div>
                </>
                : null
            }
        </div>
    )
}