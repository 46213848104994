import { useState, useEffect } from "react";

import './Brands.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Comp_Topic from "components/Topic";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Edit, Svg_Img } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Brands(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('brands'));
    const [ listBrands, setListBrands ] = useState(GetDataPage('brands'));
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listBrands.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listBrands.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('brands').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListBrands(duplicate);
        }else {
            setListBrands(GetDataPage('brands'));
        }
        setSearch(value);
    }

    function AltAccess(id, value){
        SetModalData('Confirmation', { "origin": "brands", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function AddOrEditBrands(namePage, id){
        let name = '';
        let file = '';
        if(id != 0){
            const showData = GetDataPage('brands').find(item => item.id == id);
            name = showData['name'];
            file = showData['logo'];
        }
        SetModalData('AddOrEditImg', { "origin": "brands", "id": id, "name": name, "file": file });
        SetModalState('AddOrEditImg', true);
    }

    useEffect(()=>{
        RegisterDataPage('brands', setShowData);
        return ()=>{
            UnRegisterDataPage('brands', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('brands'));
        setListBrands(GetDataPage('brands'));
    }, [showData]);

    return(
        <div className="div_inf_page">            
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Marcas" PageClick={ AddOrEditBrands } page="" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="40" align="center">Logo</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td align="center" style={ { display: "flex", alignItems: "center", justifyContent: "center" } }>
                                            {
                                                elem.logo !='' ?
                                                <img alt={ elem.name } src={ elem.logo } className="icons_logo" onClick={ ()=>{ props.OpenFile('img', elem.logo) } } />
                                                :
                                                <Svg_Img className="icons" color={ colorIcon } />
                                            }
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ AltAccess(elem.id, elem.name) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar marcar" data-tooltip-place="top">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ AddOrEditBrands('page', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Editar marcar" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 4 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listBrands.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}