
import { useState, useEffect, useRef } from "react";

import './Recover.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { CodeNewPass } from "services/Register";
import { Svg_Eye, Svg_EyeClose } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Recover(props){

    const divShowHidePassword_1 = useRef();
    const divShowHidePassword_2 = useRef();
    const [ text, setText ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ copyPass, setCopyPass ] = useState('');
    const [ statusPassword_1, setStatusPassword_1 ] = useState(false);
    const [ statusPassword_2, setStatusPassword_2 ] = useState(false);

    function ShowHidePass(setStatus, status, divAlt){
        divAlt.current.type = status ? 'password' : 'text';
        setStatus(!status);
    }

    function SaveData(event){
        props.setLoading(true);
        event.preventDefault();
        CodeNewPass('alt_password', password, props.code, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setTimeout(() => {
            setPassword('');
            setCopyPass('');
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "ok", "type": "login" });
            SetModalState('ReturnResponse', true);
            
            window.history.pushState("", "", "/");
            props.setUrl(window.location.href.split("/"));
            SetListPag('page', 'index');
        }, 1000);
    }

    function CallbackError(){
        setTimeout(() => {
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "erro", "type": "" });
            SetModalState('ReturnResponse', true);
        }, 1200);        
    }

    function CheckedPassword(){
        if(copyPass !=''){
            if(password == copyPass){
                return(
                    <button type="submit" className="div_btn">Salvar</button>
                )
            }else {
                return(
                    <div className="msg_error">As senhas devem ser iguais!</div>
                )
            }
        }else {
            return(
                <div className="msg_error">As senhas acima devem ser iguais!</div>
            )
        }
    }

    function CheckedCode(){        
        CodeNewPass('checked_code', '', props.code, ()=>{ }, ()=>{ CodeInvalid() });
    }

    function CodeInvalid(){
        setText('Este link já foi utilizado, favor solicitar um novo na página de login!');
    }

    useEffect(()=>{
        CheckedCode();
    }, []);

    useEffect(()=>{
        CheckedPassword();

        document.title = 'Recuperar senha';
    }, [copyPass]);

    return(
        <form onSubmit={ SaveData } className="Recover">
            <div className="div_data_login">
                <div className="show_data_login">
                    <div className="div_logotipo">
                        <img alt="logotipo" src="./assets/logo.png" className="logotipo" />
                    </div>
                    <div className="div_text">Recuperação de senha</div>
                    {
                        text !='' ? 
                        <>
                            <div className="msg_error">{ text }</div> 
                            <div className="div_btn" onClick={ ()=>{ SetListPag('page', 'index'); window.history.pushState("", "", "/"); props.setUrl(window.location.href.split("/")) } }>
                                <div className="button">Voltar para login</div>
                            </div>
                        </>
                        :
                        <>
                            <div className="name_input">Nova senha</div>
                            <div className="div_input">
                                <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } minLength={ 6 } placeholder="****" value={ password } ref={ divShowHidePassword_1 } required />
                                <div className="div_eye" onClick={ ()=>{ ShowHidePass(setStatusPassword_1, statusPassword_1, divShowHidePassword_1) } }>
                                    {
                                        statusPassword_1 ?
                                        <Svg_Eye className="icons" color={ colorIcon } /> :
                                        <Svg_EyeClose className="icons" color={ colorIcon } />
                                    }
                                </div>
                            </div>
                            <div className="name_input">Digite novamente a senha</div>
                            <div className="div_input">
                                <input type="password" onChange={ (e)=>{ setCopyPass(e.target.value) } } minLength={ 6 } placeholder="****" value={ copyPass } ref={ divShowHidePassword_2 } required />
                                <div className="div_eye" onClick={ ()=>{ ShowHidePass(setStatusPassword_2, statusPassword_2, divShowHidePassword_2) } }>
                                    {
                                        statusPassword_2 ?
                                        <Svg_Eye className="icons" color={ colorIcon } /> :
                                        <Svg_EyeClose className="icons" color={ colorIcon } />
                                    }
                                </div>
                            </div>
                            {
                                CheckedPassword()
                            } 
                        </>
                    }
                </div>
            </div>
        </form>
    )
}
