import { useState, useEffect } from "react";

import './Faq.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Comp_Topic from "components/Topic";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

export default function Page_Faq(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('faq'));
    const [ listData, setListData ] = useState(GetDataPage('faq'));
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('faq').forEach(item =>{
                if(item.question.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.response.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('faq'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "faq", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function AddOrEditFaq(namePage, id){
        let question = '';
        let response = '';
        if(id != 0){
            const showData = GetDataPage('faq').find(item => item.id == id);
            question = showData['question'];
            response = showData['response'];
        }
        SetModalData('Faq', { "id": id, "question": question, "response": response });
        SetModalState('Faq', true);
    }

    function TextLimit(text) {
        const textLimit = text.length > 150 ? text.substring(0, 150) + "..." : text;
        return <span dangerouslySetInnerHTML={ { __html: textLimit.replaceAll('&#34;', '"') } } />;
    }

    useEffect(()=>{
        RegisterDataPage('faq', setShowData);
        return ()=>{
            UnRegisterDataPage('faq', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('faq'));
        setListData(GetDataPage('faq'));
    }, [showData]);

    return(
        <div className="div_inf_page">    
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Faq" PageClick={ AddOrEditFaq } page="" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.question }</td>
                                        <td>{ TextLimit(elem.response) }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(elem.id, elem.question) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar faq" data-tooltip-place="top">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ AddOrEditFaq('page', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Editar faq" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 4 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}