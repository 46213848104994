import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete } from "services/SvgFile";
import { Reg_Services } from "services/Register";

export default function Page_Services_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('services'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ subtitle, setSubtitle ] = useState(InitialData('subtitle'));
    const [ time, setTime ] = useState(InitialData('time'));
    const [ text, setText ] = useState(InitialData('text'));

    const [ price, setPrice ] = useState(InitialData('price'));
    const [ discountPrice, setDiscountPrice ] = useState(InitialData('discount_price'));
    const [ gallery, setGallery ] = useState(InitialData('gallery'));

    function InitialData(type){
        const newData = GetDataPage('services').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        if(type === 'gallery'){
            return [];
        }
        return '';
    }

    /* gallery */
    function HandleImg(type, index, value){
        const newData = [...gallery];
        if(type === "file"){
            if(value){
                newData[index]['file'] = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file'] = "";
                newData[index]['status'] = false;
            }
        }else if(type === "highlight"){
            newData.map((elem, index)=>{
                elem.highlight = { "value": false, "label": "Não" }
            })
            newData[index]['highlight'] = value;
        }else {
            newData[index][type] = value;
        }
        setGallery(newData);
    }
    function DeleteImg(id, index, value){
        if(id == 0){            
            const newData = [...gallery];
            newData.splice(index, 1);
            setGallery(newData);
        }else {
            SetModalData('Confirmation', { "origin": "services", "type" : "delete_data", "status": 0, "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_Services(props.userId, idPage, title, subtitle, time, text, price, discountPrice, gallery, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('services', setDataPage);
        return ()=>{
            UnRegisterDataPage('services', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'))
        setDataPage(GetDataPage('services'));

        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setTime(InitialData('time'));
        setText(InitialData('text'));
        
        setPrice(InitialData('price'));
        setDiscountPrice(InitialData('discount_price'));
        setGallery(InitialData('gallery'));
    }, [dataPage, idPage]);

    return(
        <form className="div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do serviço" PageClick={ props.OpenPage } page="site_services" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Dados do serviço</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome do serviço" className="" setValue={ setTitle } value={ title } />

                    <Comp_Input index="not" type="text" name="Subtitulo" className="" setValue={ setSubtitle } value={ subtitle } />

                    <Comp_Input index="not" type="text" name="Tempo de serviço" className="inf_time" setValue={ setTime } value={ time } placeholder="xx min" required={ true } maxLength="10" />

                    <Comp_Input index="not" type="text" name="Valor" className="inf_price" setValue={ setPrice } value={ price } placeholder="0,00" required={ true } maxLength="10" />

                    <Comp_Input index="not" type="text" name="Promoção" className="inf_price" setValue={ setDiscountPrice } value={ discountPrice } placeholder="0,00" maxLength="10" />
                </div>

                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Descrição do produto" className="" setValue={ setText } value={ text } /> 
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Galeria de fotos</div>
                    <div className="" onClick={ ()=>{ setGallery([...gallery, { "id": 0, "title": "", "file": "", "status": false, "highlight": { "value": false, "label": "Não" } }]); } }>Adicionar nova foto</div>
                </div>
                {
                    gallery.length > 0 ?
                        gallery.map((elem, index)=>{
                            return(
                                <div className="show_content_data div_border_top" key={ index }>
                                    <Comp_Input index={ index } type="text" name="Nome" className="" setValue={ HandleImg } value={ elem.title } input="title" />
                    
                                    <Comp_Select index={ index } name="Utilizar como capa" className="highlight" opt={ [{ "value": true, "label": "Sim" }, { "value": false, "label": "Não" }] } setValue={ HandleImg } value={ elem.highlight } input="highlight" />

                                    <Comp_InputFile index={ index } setValue={ HandleImg } value={ elem.file } setStatus="" status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.thumbnail } />

                                    <div className="icon_delete" onClick={ ()=>{ DeleteImg(elem.id, index, elem.title) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhuma imagem adicionada</div>
                }
            </div>
        </form>
    )
}