import { useState, useEffect } from "react";

import './Purchase.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import Comp_Topic from "components/Topic";

export default function Page_Purchase(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('purchase'));
    const [ listData, setListData ] = useState(GetDataPage('purchase'));
    
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('purchase').forEach(item =>{
                if(item.register_date.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.name_status.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.price.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('purchase'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('purchase', setShowData);
        return ()=>{
            UnRegisterDataPage('purchase', setShowData);
        };
    }, []);

    useEffect(()=>{
        setListData(GetDataPage('purchase'));
    }, [showData]);

    return(
        <div className="div_inf_page">
            <Comp_Topic type={ false } icon="" SearchInput={ SearchInput } search={ search } title="Compras do site" PageClick={ props.OpenPage } page="purchase_details" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Data da compra</th>
                            <th width="70">Qtd Item</th>
                            <th width="70">Valor</th>
                            <th width="60">Status</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.register_date_br }</td>
                                        <td>{ elem.qtd_item }</td>
                                        <td>{ elem.price }</td>
                                        <td>{ elem.status['label'] }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ props.OpenPage('purchase_details', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Visualizar pedido de compra" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 5 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}