import { useState, useEffect } from "react";

import './Topic.css';

import { Svg_AddNewData, Svg_ArrowRight, Svg_Save, Svg_Search } from "services/SvgFile";

import Comp_ReturnPage from "components/ReturnPage";
import Comp_Search from "components/Search";

export default function Comp_Topic(props){

    return(
        <div className="Comp_Topic">
            <div className="new_block">
                {
                    props.SearchInput ? 
                        props.SearchInput === "no_return" ? null :
                        <Comp_Search SearchInput={ props.SearchInput } search={ props.search } />
                        :
                        <Comp_ReturnPage PageClick={ props.PageClick } page={ props.page } idPage={ props.idPage } />
                }
                <div className="title_page">
                    { props.title }
                </div>
                <div className="list_opt_alt_page">
                    {
                        props.type ? 
                            props.icon === "add" ?
                            <div type="submit" className="div_add_new_data" onClick={ ()=>{ props.PageClick(props.page, props.idPage) } }>
                                <div className="new_data_add">
                                    <Svg_AddNewData className="icons" color="#ffffff" />
                                </div>
                                <div className="name_btn_add">Adicionar</div>
                            </div>
                            :
                            <button type="submit" className="div_add_new_data">
                                <div className="new_data_add">
                                    <Svg_Save className="icons" color="#ffffff" />
                                </div>
                                <div className="name_btn_add">Salvar</div>
                            </button>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}