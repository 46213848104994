import { useState, useEffect } from "react";

import './Details.css';

import ReactApexChart from "react-apexcharts";

import { GetDataPage } from "interface/Data";

import { colorIcon } from "fixedData";
import { Svg_Arrow, Svg_ArrowRight } from "services/SvgFile";

export default function Page_Details(props){

    const date = new Date();
    const [ showMonth, setShowMonth ]     = useState(date.getMonth() + 1);
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    const [ qtdProduct, setQtdProduct ] = useState(GetDataPage('product'));
    
    const [ series, setSeries ]   = useState(ShowCurrentYear(currentYear));
    const [ options, setOptions ] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: '',
            align: ''
        },
        colors:['#7c9f06', '#ff001c'],
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        }
    });

    function ShowCurrentYear(value){
        let dataMonth = [0, 0, 80, 100, 200, 700, 0, 0, 0, 0, 0, 0];      
        return [
            {
                name: "Venda",
                data: dataMonth
            }
        ];
    }

    function TicketMedio(){
        let value = 2000 / 6;        
        let showValue = value.toString().split('.');
        return showValue[0] + '.' + showValue[1].substring(0, 2);
    }

    return(
        <div className="div_inf_page inf_dashboard">
            <div className="div_show_year">
                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <Svg_ArrowRight className="icons left" color={ colorIcon } />
                </div>

                <div className="show_year">
                    { currentYear }
                </div>

                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <Svg_ArrowRight className="icons" color={ colorIcon } />
                </div>
            </div>

            <div className="list_data">
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">{ qtdProduct.length }</div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Produtos cadastrados
                    </div>
                </div>
                
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">6</div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Pedidos
                    </div>
                </div>
                
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">2.000,00</div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Valor das vendas
                    </div>
                </div>
                
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">
                        {
                            TicketMedio()
                        }
                    </div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Ticket médio
                    </div>
                </div>
            </div>
            
            <div className="div_grafic">
                <ReactApexChart options={ options } series={ series } type="line" height={ 350 } />
            </div>
        </div>
    )
}