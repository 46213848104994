import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";
import Comp_InputCheckbox from "components/InputCheckbox";

import { GetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete } from "services/SvgFile";
import { Reg_Product } from "services/Register";

export default function Page_Product_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('product'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ subtitle, setSubtitle ] = useState(InitialData('subtitle'));
    const [ price, setPrice ] = useState(InitialData('price'));
    const [ discountPrice, setDiscountPrice ] = useState(InitialData('discount_price'));
    const [ size, setSize ] = useState(InitialData('size'));
    const [ qtd, setQtd ] = useState(InitialData('qtd'));
    const [ side, setSide ] = useState(InitialData('side'));
    const [ brands, setBrands ] = useState(InitialData('brands'));
    const [ line, setLine ] = useState(InitialData('line'));
    const [ model, setModel ] = useState(InitialData('model'));
    const [ loadIndex, setLoadIndex ] = useState(InitialData('load_index'));
    const [ speedIndex, setSpeedIndex ] = useState(InitialData('speed_index'));
    const [ loadRange, setLoadRange ] = useState(InitialData('load_range'));
    const [ typeConstruction, setTypeConstruction ] = useState(InitialData('type_construction'));
    const [ appearanceTire, setAppearanceTire ] = useState(InitialData('appearance_tire'));
    const [ typeService, setTypeService ] = useState(InitialData('type_service'));
    const [ vehicleType, setVehicleType ] = useState(InitialData('vehicle_type'));
    const [ typeTerrain, setTypeTerrain ] = useState(InitialData('type_terrain'));
    const [ technology, setTechnology ] = useState(InitialData('technology'));
    const [ aspectRatio, setAspectRatio ] = useState(InitialData('aspect_ratio'));
    const [ sectionWidth, setSectionWidth ] = useState(InitialData('section_width'));
    const [ wheelDiameter, setWheelDiameter ] = useState(InitialData('wheel_diameter'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ gallery, setGallery ] = useState(InitialData('gallery'));

    function InitialData(type){
        const newData = GetDataPage('product').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        if(type === 'gallery' || type === 'color'){
            return [];
        }
        if(type == 'size_pp' || type == 'size_p' || type == 'size_m' || type == 'size_g' || type == 'size_xg'){
            return false;
        }
        return '';
    }

    /* gallery */
    function HandleImg(type, index, value){
        const newData = [...gallery];
        if(type === "file"){
            if(value){
                newData[index]['file'] = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file'] = "";
                newData[index]['status'] = false;
            }
        }else if(type === "highlight"){
            newData.map((elem, index)=>{
                elem.highlight = { "value": false, "label": "Não" }
            })
            newData[index]['highlight'] = value;
        }else {
            newData[index][type] = value;
        }
        setGallery(newData);
    }
    function DeleteImg(id, index, value){
        if(id == 0){            
            const newData = [...gallery];
            newData.splice(index, 1);
            setGallery(newData);
        }else {
            SetModalData('Confirmation', { "origin": "product", "type" : "delete_gallery", "status": 0, "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_Product(props.userId, idPage, title, subtitle, price, discountPrice, size, qtd, side, brands, line, model, loadIndex, speedIndex, loadRange, typeConstruction, appearanceTire, typeService, vehicleType, typeTerrain, technology, aspectRatio, sectionWidth, wheelDiameter, text, gallery, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('product', setDataPage);
        return ()=>{
            UnRegisterDataPage('product', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('product'));
        
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setPrice(InitialData('price'));
        setDiscountPrice(InitialData('discount_price'));
        setSize(InitialData('size'));
        setQtd(InitialData('qtd'));
        setSide(InitialData('side'));
        setBrands(InitialData('brands'));
        setLine(InitialData('line'));
        setModel(InitialData('model'));
        setLoadIndex(InitialData('load_index'));
        setSpeedIndex(InitialData('speed_index'));
        setLoadRange(InitialData('load_range'));
        setTypeConstruction(InitialData('type_construction'));
        setAppearanceTire(InitialData('appearance_tire'));
        setTypeService(InitialData('type_service'));
        setVehicleType(InitialData('vehicle_type'));
        setTypeTerrain(InitialData('type_terrain'));
        setTechnology(InitialData('technology'));
        setAspectRatio(InitialData('aspect_ratio'));
        setSectionWidth(InitialData('section_width'));
        setWheelDiameter(InitialData('wheel_diameter'));
        setText(InitialData('text'));
        setGallery(InitialData('gallery'));
    }, [dataPage, idPage]);

    return(
        <form className="div_show_data_product div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do produto" PageClick={ props.OpenPage } page="site_product" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Características principais</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Valor" className="inf_input" setValue={ setPrice } value={ price } placeholder="0,00" />

                    <Comp_Input index="not" type="text" name="Promoção" className="inf_input" setValue={ setDiscountPrice } value={ discountPrice } placeholder="0,00" />

                    <Comp_Input index="not" type="text" name="Lateral" className="size" setValue={ setSide } value={ side } />

                    <Comp_Input index="not" type="text" name="Qtd" className="qtd" setValue={ setQtd } value={ qtd } placeholder="xx" />
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome do produto" className="" setValue={ setTitle } value={ title } />

                    <Comp_Input index="not" type="text" name="Subtitulo" className="" setValue={ setSubtitle } value={ subtitle } />
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Descrição do produto" className="" setValue={ setText } value={ text } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Características gerais</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Marcas" className="brands" setValue={ setBrands } value={ brands } />
                    
                    <Comp_Input index="not" type="text" name="Linha" className="brands" setValue={ setLine } value={ line } />                

                    <Comp_Input index="not" type="text" name="Modelo" className="brands" setValue={ setModel } value={ model } />

                    <Comp_Input index="not" type="text" name="Tamanho" className="size" setValue={ setSize } value={ size } placeholder="xx/xxxx" />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Especificações</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Índice de carga" className="brands" setValue={ setLoadIndex } value={ loadIndex } />
                    
                    <Comp_Input index="not" type="text" name="Índice de velocidade" className="brands" setValue={ setSpeedIndex } value={ speedIndex } />
                    
                    <Comp_Input index="not" type="text" name="Faixa de carga" className="brands" setValue={ setLoadRange } value={ loadRange } />
                    
                    <Comp_Input index="not" type="text" name="Tipo de construção" className="brands" setValue={ setTypeConstruction } value={ typeConstruction } />
                    
                    <Comp_Input index="not" type="text" name="Relação de aspecto do pneu" className="" setValue={ setAspectRatio } value={ aspectRatio } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Uso</div>
                <div className="show_content_data">                    
                    <Comp_Input index="not" type="text" name="Tipo de serviço" className="brands" setValue={ setTypeService } value={ typeService } />
                    
                    <Comp_Input index="not" type="text" name="Tipo de veículo" className="brands" setValue={ setVehicleType } value={ vehicleType } />
                    
                    <Comp_Input index="not" type="text" name="Tipo de terreno" className="brands" setValue={ setTypeTerrain } value={ typeTerrain } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Tecnologia</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Tecnologia" className="" setValue={ setTechnology } value={ technology } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Dimensões</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Largura de secção" className="brands" setValue={ setSectionWidth } value={ sectionWidth } />
                    
                    <Comp_Input index="not" type="text" name="Diâmetro de roda" className="brands" setValue={ setWheelDiameter } value={ wheelDiameter } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Galeria de fotos</div>
                    <div className="" onClick={ ()=>{ setGallery([...gallery, { "id": 0, "title": "", "file": "", "status": false, "highlight": { "value": false, "label": "Não" } }]); } }>Adicionar nova foto</div>
                </div>
                {
                    gallery.length > 0 ?
                        gallery.map((elem, index)=>{
                            return(
                                <div className="show_content_data div_border_top" key={ index }>
                                    <Comp_Input index={ index } type="text" name="Nome" className="" setValue={ HandleImg } value={ elem.title } input="title" />
                    
                                    <Comp_Select index={ index } name="Utilizar como capa" className="highlight" opt={ [{ "value": true, "label": "Sim" }, { "value": false, "label": "Não" }] } setValue={ HandleImg } value={ elem.highlight } input="highlight" />

                                    <Comp_InputFile index={ index } setValue={ HandleImg } value={ elem.file } setStatus="" status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.thumbnail } />

                                    <div className="icon_delete" onClick={ ()=>{ DeleteImg(elem.id, index, elem.title) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhuma imagem adicionada</div>
                }
            </div>
        </form>
    )
}