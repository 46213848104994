import TextareaAutosize from 'react-textarea-autosize';

import './Textarea.css';

export default function Comp_Textarea(props){
    return(
        <div className="Comp_Textarea">            
            <TextareaAutosize className="textarea" onChange={ (e)=>{ props.index === "not" ? props.setValue(e.target.value) : props.setValue(props.input, props.index, e.target.value) } } value={ props.value.replace(/<br\s*\/?>/gi, '\n') } placeholder="digite aqui a informação..." />
            <div className="name">{ props.name }</div>
        </div>
    )
}