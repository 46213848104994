import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';


// Delete or alt status data
export function Reg_AltStatus(userId, origin, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('id', id);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : process.env.REACT_APP_API_URL + 'email/recover_access.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        switch (response.data) {
            case "Email successfully sent": CallbackSuccess(); break;
            case "Error sending message": CallbackError_Send(); break;
            case "Email not sent": CallbackError_NotEmail(); break;
            case "Email not found": CallbackError(); break;
            default: CallbackError(); break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : process.env.REACT_APP_API_URL + 'email/recover_access.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        switch (response.data) {
            case "Email successfully sent": case "connected":
                    CallbackSuccess(); 
                break;
            default: 
                    CallbackError(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Checked Data
export function CheckedData(edit_data, start, hour, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'checked_data');
    data.append('edit_data', edit_data);
    data.append('start', start);
    data.append('hour', hour);

    Axios({
        url : process.env.REACT_APP_API_URL + 'inc/checked_data.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "denied":
                    CallbackError();                
                break;
            case "released":
                    CallbackSuccess();                
                break;
        }
        

    }).catch((error)=>{
        CallbackError();
    })
}


// Add or edit img
export function Reg_File(origin, id, name, file, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', 'edit_or_register');
    data.append('id', id);
    data.append('name', name);
    data.append('file', file);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Site
export function Reg_Site(userId, id, title, origin_, text, id_banner_1, id_banner_2, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', 'site');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('origin_', origin_['value']);
    data.append('origin_name', origin_['label']);
    data.append('text', text);
    data.append('id_banner_1', id_banner_1['value']);
    data.append('id_banner_1_name', id_banner_1['label']);
    data.append('id_banner_2', id_banner_2['value']);
    data.append('id_banner_2_name', id_banner_2['label']);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('site', response.data.site);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Site - alt order
export function Reg_SiteAltOrder(userId, id, type, order, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'site');
    data.append('edit_data', 'alt_order');
    data.append('userId', userId);
    data.append('id', id);
    data.append('type', type);
    data.append('order', order);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('site', response.data.site);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Category
export function Reg_Category(id, title, file, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'category');
    data.append('edit_data', 'edit_or_register');
    data.append('id', id);
    data.append('title', title);
    data.append('file', file);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/category.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('category', response.data.category);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Purchase
export function Reg_Purchase(userId, idPage, status, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'purchase');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('status', status);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/purchase.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('purchase', response.data.purchase);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Brands
export function Reg_Brands(id, name, logo, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'brands');
    data.append('edit_data', 'edit_or_register');
    data.append('id', id);
    data.append('name', name);
    data.append('logo', logo);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/brands.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('brands', response.data.brands);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Banner
export function Reg_Banner(id, name, banner, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'banner');
    data.append('edit_data', 'edit_or_register');
    data.append('id', id);
    data.append('name', name);
    data.append('banner', banner);

    console.log(id, name, banner);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/banner.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('banner', response.data.banner);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Faq
export function Reg_Faq(id, question, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'faq');
    data.append('edit_data', 'edit_or_register');
    data.append('id', id);
    data.append('question', question);
    data.append('response', text);
    console.log(id, question, text);
    Axios({
        url : process.env.REACT_APP_API_URL + 'page/faq.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('faq', response.data.faq);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Product
export function Reg_Product(userId, id, title, subtitle, price, discountPrice, size, qtd, side, brands, line, model, loadIndex, speedIndex, loadRange, typeConstruction, appearanceTire, typeService, vehicleType, typeTerrain, technology, aspectRatio, sectionWidth, wheelDiameter, text, gallery, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'product');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('price', price);
    data.append('discount_price', discountPrice);
    data.append('size', size);
    data.append('qtd', qtd);
    data.append('side', side);
    data.append('brands', brands);
    data.append('line', line);
    data.append('model', model);
    data.append('load_index', loadIndex);
    data.append('speed_index', speedIndex);
    data.append('load_range', loadRange);
    data.append('type_construction', typeConstruction);
    data.append('appearance_tire', appearanceTire);
    data.append('type_service', typeService);
    data.append('vehicle_type', vehicleType);
    data.append('type_terrain', typeTerrain);
    data.append('technology', technology);
    data.append('aspect_ratio', aspectRatio);
    data.append('section_width', sectionWidth);
    data.append('wheel_diameter', wheelDiameter);
    data.append('text', text);

    // gallery
    gallery.forEach((elem, index) => {
        data.append('gallery_id[]', elem.id);
        data.append('gallery_title[]', elem.title);
        data.append('gallery_highlight[]', elem.highlight['value']);
        data.append('gallery_file_' + index, elem.file);
    });

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/product.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('product', response.data.product);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Services
export function Reg_Services(userId, id, title, subtitle, time, text, price, discountPrice, gallery, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('time', time);
    data.append('text', text);
    data.append('price', price);
    data.append('discount_price', discountPrice);

    // gallery
    gallery.forEach((elem, index) => {
        data.append('gallery_id[]', elem.id);
        data.append('gallery_title[]', elem.title);
        data.append('gallery_highlight[]', elem.highlight['value']);
        data.append('gallery_file_' + index, elem.file);
    });

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/services.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('services', response.data.services);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// AccessDash
export function Reg_AccessDash(userId, id, name, email, password, file, nivel, note, page, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password);
    data.append('file', file);
    data.append('nivel', nivel.value);
    data.append('note', note);

    // page
    if(nivel.value == 0){
        page.forEach((elem, index) => {
            data.append('page_id[]', elem.id);
            data.append('page_order_[]', elem.order_);
            data.append('page_type[]', elem.type);
            data.append('page_page[]', elem.page);
            data.append('page_name[]', elem.name);
        });
    }

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        SetListPag('currentPageId', response.data.idPage);

        if(userId == id){
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 3);
    
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);

            if(response.data.pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }            

            SetUserData('file', response.data.file);
            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }

        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// AccessSite
export function Reg_AccessSite(userId, id, name, email, password, file, note, complement, phone, cep, address, street, state, city, neighborhood, number, purchase, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'access_site');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password);
    data.append('file', file);
    data.append('note', note);
    data.append('complement', complement);
    data.append('phone', phone);
    data.append('cep', cep);
    data.append('address', address);
    data.append('street', street);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('number', number);

    purchase.forEach((elem, index) => {
        
    });

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/access_site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('access_site', response.data.access_site);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Settings
export function Reg_Settings(userId, facebook, instagram, youtube, tiktok, linkedin, companyName, email, whatsApp, logo, defaultColor, buttonColor, titleColor, footerTextColor, about, privacyPolicy, regulations, consentForm, security, termsConditions, benefits, cancellationPolicy, codeEthics, confidentialChannel, listBtn, scriptMeta, scriptBody, htmlHeader, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'settings');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    
    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('youtube', youtube);
    data.append('tiktok', tiktok);
    data.append('linkedin', linkedin);
    data.append('company_name', companyName);
    data.append('email', email);
    data.append('whatsapp', whatsApp);
    data.append('logo', logo);
    data.append('default_color', defaultColor);
    data.append('button_color', buttonColor);
    data.append('title_color', titleColor);
    data.append('footer_text_color', footerTextColor);
    data.append('about', about);
    data.append('privacy_policy', privacyPolicy);
    data.append('regulations', regulations);
    data.append('consent_form', consentForm);
    data.append('security', security);
    data.append('terms_conditions', termsConditions);
    data.append('benefits', benefits);
    data.append('cancellation_policy', cancellationPolicy);
    data.append('code_ethics', codeEthics);
    data.append('confidential_channel', confidentialChannel);
    data.append('script_meta', scriptMeta);
    data.append('script_body', scriptBody);
    data.append('html_header', htmlHeader);
    
    listBtn.forEach((elem, index) => {
        data.append('btn_id[]', elem.id);
        data.append('btn_title[]', elem.title);
        data.append('btn_subtitle[]', elem.subtitle);
        data.append('btn_file_' + index, elem.file);
    });

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/settings.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('settings', response.data.settings);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Schedule
export function Reg_Schedule(userId, id, idGiven, title, status, subtitle, price, text, hour, start, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'schedule');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('id_given', idGiven);
    data.append('title', title);
    data.append('status', status);
    data.append('subtitle', subtitle);
    data.append('price', price);
    data.append('text', text);
    data.append('hour', hour);
    data.append('start', start);

    console.log(idGiven, title, status);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/schedule.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('schedule', response.data.schedule);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}