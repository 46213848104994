import { useState, useEffect } from "react";

import './Product.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import Comp_Topic from "components/Topic";

export default function Page_Product(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('product'));
    const [ listData, setListData ] = useState(GetDataPage('product'));
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('product').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.subttile.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.price.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('product'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "product", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function TextLimit(text) {
        const textLimit = text.length > 150 ? text.substring(0, 150) + "..." : text;
        return <span dangerouslySetInnerHTML={ { __html: textLimit.replaceAll('&#34;', '"') } } />;
    }

    useEffect(()=>{
        RegisterDataPage('product', setShowData);
        return ()=>{
            UnRegisterDataPage('product', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('product'));
        setListData(GetDataPage('product'));
    }, [showData]);

    return(
        <div className="div_inf_page">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Produtos" PageClick={ props.OpenPage } page="site_product_details" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="300">Nome</th>
                            <th>Descrição</th>
                            <th width="60">Valor</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ TextLimit(elem.text) }</td>
                                        <td>{ elem.price }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(elem.id, elem.title) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar produto" data-tooltip-place="top">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ props.OpenPage('site_product_details', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Editar produto" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 5 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}