import { useState, useEffect } from "react";

import './AccessDash.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import Comp_Topic from "components/Topic";

export default function Page_AccessDash(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('access_dash'));
    const [ listData, setListData ] = useState(GetDataPage('access_dash'));
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('access_dash').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.note.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('access_dash'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setShowData);
        return ()=>{
            UnRegisterDataPage('access_dash', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('access_dash'));
        setListData(GetDataPage('access_dash'));
    }, [showData]);

    return(
        <div className="div_inf_page">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Acesso da dashboard" PageClick={ props.OpenPage } page="access_dash_details" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="180">E-mail</th>
                            <th width="110">Tipo de acesso</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td>{ elem.email }</td>
                                        <td align="center">{ elem.nivel['value'] == 0 ? "Usuário" : "Administrador" }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    props.userId == elem.id ? null :
                                                    <div onClick={ ()=>{ DeleteData(elem.id, elem.title) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar acesso" data-tooltip-place="top">
                                                        <Svg_Delete color="#F00000" className="icons"/>
                                                    </div>
                                                }

                                                <div onClick={ ()=>{ props.OpenPage('access_dash_details', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Editar acesso" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 5 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}