import './Search.css';

import { Svg_Search } from 'services/SvgFile';

export default function Comp_Search(props){
    return(
        <div className="input_search">                             
            <Svg_Search color="#606875" className="icons_search" />
            <input type="text" className="search" onChange={ (e)=>{ props.SearchInput(e.target.value) } } value={ props.search } placeholder="digite aqui sua pesquisa..." />
        </div>
    )
}