import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { cepMask, cpfMask, phoneMask } from "services/Mask";
import { Reg_AccessSite } from "services/Register";

export default function Page_UserSite_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_site'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ password, setPassword ] = useState('');
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ thumbnail, setThumbnail ] = useState(InitialData('thumbnail'));
    const [ note, setNote ] = useState(InitialData('note'));
    const [ complement, setComplement ] = useState(InitialData('complement'));
    const [ phone, setPhone ] = useState(InitialData('phone'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ address, setAddress ] = useState(InitialData('address'));
    const [ street, setStreet ] = useState(InitialData('street'));
    const [ state, setState ] = useState(InitialData('state'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ neighborhood, setNeighborhood ] = useState(InitialData('neighborhood'));
    const [ number, setNumber ] = useState(InitialData('number'));
    const [ purchase, setPurchase ] = useState(InitialData('purchase'));

    function InitialData(type){
        const newData = GetDataPage('access_site').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_AccessSite(props.userId, idPage, name, email, password, file, note, complement, phone, cep, address, street, state, city, neighborhood, number, purchase, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('access_site', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_site', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'))
        setDataPage(GetDataPage('access_site'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPassword('');
        setFile(InitialData('file'));
        setFileStatus(false);
        setThumbnail(InitialData('thumbnail'));        
        setNote(InitialData('note'));
        setComplement(InitialData('complement'));
        setPhone(InitialData('phone'));
        setCep(InitialData('cep'));
        setAddress(InitialData('address'));
        setStreet(InitialData('street'));
        setState(InitialData('state'));
        setCity(InitialData('city'));
        setNeighborhood(InitialData('neighborhood'));
        setNumber(InitialData('number'));
        setPurchase(InitialData('purchase'));
    }, [dataPage, idPage]);

    return(
        <form className="div_show_data_access div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do acesso" PageClick={ props.OpenPage } page="user_site" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Dados do acesso</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome" className="" setValue={ setName } value={ name } />

                    <Comp_Input index="not" type="text" name="Contato" className="phone" setValue={ setPhone } value={ phoneMask(phone) } placeholder="(xx) xxxxx-xxxx" />

                    <Comp_Input index="not" type="email" name="E-mail" className="" setValue={ setEmail } value={ email } />

                    <Comp_Input index="not" type="password" name="Senha" className="inf_input" setValue={ setPassword } value={ password } maxLength={ 10 } placeholder="****" />
                    
                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ thumbnail } />
                </div>

                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Observação" className="" setValue={ setNote } value={ note } /> 
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Endereço</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="CEP" className="cep" setValue={ setCep } value={ cepMask(cep) } maxLength={ 9 } placeholder="xxxxx-xx" />
                    
                    <Comp_Input index="not" type="text" name="Cidade" className="" setValue={ setCity } value={ city } />
                    
                    <Comp_Input index="not" type="text" name="UF" className="state" setValue={ setState } value={ state } />

                    <Comp_Input index="not" type="text" name="Bairro" className="" setValue={ setNeighborhood } value={ neighborhood } />

                    <Comp_Input index="not" type="text" name="Rua" className="" setValue={ setStreet } value={ street } />

                    <Comp_Input index="not" type="text" name="Número" className="number" setValue={ setNumber } value={ number } placeholder="xxxx" />
                </div>

                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Complemento" className="" setValue={ setComplement } value={ complement } maxLength={ 140 } />
                </div>
            </div>
            {
                idPage !=0 ?
                    <div className="show_data_page">
                        <div className="div_opt_data">Compras realizadas</div>
                        <div className="show_content_data">
                            {
                                purchase.length > 0 ?                                
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th width="20" align="center">#</th>
                                            <th>Data da compra</th>
                                            <th width="90" align="center">Qtd de item</th>
                                            <th width="70">Valor R$</th>
                                            <th width="40">Status</th>
                                        </tr>
                                    </thead>
                                    {
                                        purchase.map((elem, index)=>{
                                            return(
                                                <tbody key={ index }>
                                                    <tr>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td>{ elem.register_date }</td>
                                                        <td align="center">{ elem.qtd_item }</td>
                                                        <td>{ elem.price }</td>
                                                        <td>{ elem.name_status }</td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                                : <div className="no_data">Nenhum dado encontrado...</div>
                            }
                        </div>
                    </div>
                : null
            }
        </form>
    )
}