import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Purchase } from "services/Register";

export default function Page_Purchase_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('purchase'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('purchase'));

    const [ idClient, setIdClient ] = useState(InitialData('id_client'));
    const [ nameClient, setNameClient ] = useState(InitialData('name_client'));
    const [ status, setStatus ] = useState(InitialData('status'));
    const [ qtdItem, setQtdItem ] = useState(InitialData('qtd_item'));
    const [ price, setPrice ] = useState(InitialData('price'));
    const [ registerDate, setRegisterDate ] = useState(InitialData('register_date_br'));
    const [ datePayment, setDatePayment ] = useState(InitialData('date_payment'));
    const [ list, setList ] = useState(InitialData('list'));

    function InitialData(type){
        const newData = GetDataPage('purchase').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_Purchase(props.userId, idPage, status['value'], props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('purchase', setDataPage);
        return ()=>{
            UnRegisterDataPage('purchase', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'))
        setDataPage(GetDataPage('purchase'));

        setIdClient(InitialData('id_client'));
        setNameClient(InitialData('name_client'));
        setStatus(InitialData('status'));
        setQtdItem(InitialData('qtd_item'));
        setPrice(InitialData('price'));
        setRegisterDate(InitialData('register_date_br'));
        setDatePayment(InitialData('date_payment'));
        setList(InitialData('list'));
    }, [dataPage, idPage]);

    return(
        <form className="div_show_data_access div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do acesso" PageClick={ props.OpenPage } page="user_site" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Dados da compra</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome do cliente" className="" setValue={ setNameClient } value={ nameClient } readOnly={ true} />

                    <Comp_Input index="not" type="text" name="Data da compra" className="register_date" setValue={ setRegisterDate } value={ registerDate } readOnly={ true} />

                    <Comp_Input index="not" type="text" name="Qtd" className="qtd_item" setValue={ setQtdItem } value={ qtdItem } placeholder="" readOnly={ true} />

                    <Comp_Input index="not" type="text" name="Valor R$" className="price" setValue={ setPrice } value={ price } placeholder="" readOnly={ true} />
                    
                    <Comp_Select index="not" name="Status" className="type_status" opt={ [{ "value": 0, "label": "Pendente" }, { "value": 1, "label": "Finalizado" }, { "value": 2, "label": "Cancelado" }] } setValue={ setStatus } value={ status } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">Lista dos produtos</div>
                <div className="show_content_data">
                    {
                        list.length > 0 ?                                
                        <table cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th width="20" align="center">#</th>
                                    <th>Título</th>
                                    <th>Subtitulo</th>
                                    <th width="90" align="center">Qtd de item</th>
                                    <th width="70">Valor R$</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td>{ elem.title }</td>
                                                <td>{ elem.subtitle }</td>
                                                <td align="center">{ elem.qtd }</td>
                                                <td>{ elem.price }</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        : <div className="no_data">Nenhum dado encontrado...</div>
                    }
                </div>
            </div>
        </form>
    )
}