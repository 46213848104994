import React, { useState, useEffect } from "react";

import './InputColor.css';

import { HuePicker } from "react-color";
import { Saturation } from '@uiw/react-color';

import { RegisterModalData, RegisterModalObserver, SetModalState } from 'interface/PopUp';

import { Svg_ArrowRight } from "services/SvgFile";

export default function PopUp_Color(props) {

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ typeColor, setTypeColor ] = useState('Hex');
    const [ color, setColor ]         = useState({
        "hex"    : "#000000", 
        "hsl"    : { h: 0, s: 0, l: 0, a: 1 }, 
        "hsv"    : { h: 0, s: 0, v: 0, a: 1 },
        "oldHue" : 0,
        "rgb"    : { r: 0, g: 0, b: 0, a: 1 } 
    });
    const [ colorHex, setColorHex ] = useState(color.hex);

    function hsv2hsl(hsvH, hsvS, hsvV, type=null) {
        const hslL = (200 - hsvS) * hsvV / 100;
        const [ hslS, hslV ] = [
            hslL === 0 || hslL === 200 ? 0 : hsvS * hsvV / 100 / (hslL <= 100 ? hslL : 200 - hslL) * 100,
            hslL * 5 / 10
        ];
        if(type){
            return { h: hsvH, s: hslS, l: hslV, a: 1 };
        }
        return [ hsvH, hslS, hslV ];
    }

    function hslToRgb (h, s, l) {
        s /= 100;
        l /= 100;
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
        return [Math.round(255 * f(0)), Math.round(255 * f(8)), Math.round(255 * f(4))];
    };
    
    function mapMinMax(value,oldMin,oldMax,newMin, newMax) {
        return (newMax-newMin)*(value-oldMin)/(oldMax-oldMin)+newMin;
    }

    function rgbToHex(r, g, b) {        
        const rgb = (r << 16) | (g << 8) | (b << 0);
        return (0x1000000 + rgb).toString(16).slice(1);
    }

    function rgbToHsl(r, g, b){
        r /= 255;
        g /= 255;
        b /= 255;
        const l = Math.max(r, g, b);
        const s = l - Math.min(r, g, b);
        const h = s
          ? l === r
            ? (g - b) / s
            : l === g
            ? 2 + (b - r) / s
            : 4 + (r - g) / s
          : 0;
        return { 
            h: 60 * h < 0 ? 60 * h + 360 : 60 * h,
            s: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
            l: (100 * (2 * l - s)) / 2,
            a: 1
        };
    }

    function rgbToHsv(r, g, b){
        r /= 255; 
        g /= 255;
        b /= 255;
    
        var max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        var h, s, v = max;
    
        var d = max - min;
        s = max == 0 ? 0 : d / max;
        if (max == min) {
            h = 0; // achromatic
        } else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
    
            h /= 6;
        }
    
        h = mapMinMax(h,0,1,0,360);
        return {h: h, s: s * 100, v: v * 100};
    }

    function hsvToHex(h, s, v){
        const hsl = hsv2hsl(h, s, v);
        const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
        const hex = rgbToHex(rgb[0], rgb[1], rgb[2]);
        return hex;
    }

    function hsvToRgb(h, s, v){
        const hsl = hsv2hsl(h, s, v);
        const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
        return { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 };
    }

    function hexToRgb(value){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 1
        } : null;
    }

    function hexToHsl(value){
        let r = 0, g = 0, b = 0;
        if (value.length == 4) {
            r = "0x" + value[1] + value[1];
            g = "0x" + value[2] + value[2];
            b = "0x" + value[3] + value[3];
        } else if (value.length == 7) {
            r = "0x" + value[1] + value[2];
            g = "0x" + value[3] + value[4];
            b = "0x" + value[5] + value[6];
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r,g,b),
            cmax = Math.max(r,g,b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;
      
        if (delta == 0){
            h = 0;
        }else if (cmax == r){
            h = ((g - b) / delta) % 6;
        }else if (cmax == g){
            h = (b - r) / delta + 2;
        }else {
            h = (r - g) / delta + 4;
        }
        h = Math.round(h * 60);
      
        if (h < 0){
            h += 360;
        }
        
        l = (cmax + cmin) / 2;
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);
      
        return { h: h, s: s, l: l, a: 1 } 
        // "hsl(" + h + "," + s + "%," + l + "%)";
    }

    function hexToHsv(value){
        const rgb = hexToRgb(value);
        const hsv = rgbToHsv(rgb.r, rgb.g, rgb.b);
        return hsv;
    }

    function AltColorHsv(hsv){
        setColorHex("#" + hsvToHex(hsv.h, hsv.s, hsv.v));
        setColor({ 
            "hex"    : "#" + hsvToHex(hsv.h, hsv.s, hsv.v), 
            "hsl"    : hsv2hsl(hsv.h, hsv.s, hsv.v, 'register'), 
            "hsv"    : hsv, 
            "oldHue" : color.oldHue, 
            "rgb"    : hsvToRgb(hsv.h, hsv.s, hsv.v)
        });
    } 

    function AltColorR(value){
        setColorHex("#" + rgbToHex(value, color.rgb.g, color.rgb.b));
        setColor({ 
            "hex"    : "#" + rgbToHex(value, color.rgb.g, color.rgb.b), 
            "hsl"    : rgbToHsl(value, color.rgb.g, color.rgb.b), 
            "hsv"    : rgbToHsv(value, color.rgb.g, color.rgb.b), 
            "oldHue" : color.oldHue, 
            "rgb"    : { r: value, g: color.rgb.g, b: color.rgb.b, a: 1 }
        });
    } 

    function AltColorG(value){
        setColorHex("#" + rgbToHex(color.rgb.r, value, color.rgb.b));
        setColor({ 
            "hex"    : "#" + rgbToHex(color.rgb.r, value, color.rgb.b), 
            "hsl"    : rgbToHsl(color.rgb.r, value, color.rgb.b), 
            "hsv"    : rgbToHsv(color.rgb.r, value, color.rgb.b), 
            "oldHue" : color.oldHue, 
            "rgb"    : { r: color.rgb.r, g: value, b: color.rgb.b, a: 1 }
        });
    }

    function AltColorB(value){
        setColorHex("#" + rgbToHex(color.rgb.r, color.rgb.g, value));
        setColor({ 
            "hex"    : "#" + rgbToHex(color.rgb.r, color.rgb.g, value), 
            "hsl"    : rgbToHsl(color.rgb.r, color.rgb.g, value), 
            "hsv"    : rgbToHsv(color.rgb.r, color.rgb.g, value), 
            "oldHue" : color.oldHue, 
            "rgb"    : { r: color.rgb.r, g: color.rgb.g, b: value, a: 1 }
        });
    }

    function AltColorHex(value){
        setColorHex(value);
        if(value.length > 6){
            setColor({ 
                "hex"    : value, 
                "hsl"    : hexToHsl(value), 
                "hsv"    : hexToHsv(value), 
                "oldHue" : color.oldHue, 
                "rgb"    : hexToRgb(value)
            });
        }
    }

    function SelectTypeColor(value){
        switch(value){
            case "Hex":
                    setTypeColor('Rgb');
                break;
            case "Rgb":
                    setTypeColor('Hex');
                break;
        }
    }

    function RegisterColor(){
        if(modalData.type){
            modalData.ShowPalette(modalData.type, modalData.setValue, color.hex);
        }else {
            modalData.RegisterColor(modalData.id, modalData.index, color.hex);
        }
        ClosePopUp();
    }

    function ClosePopUp(){
        SetModalState('Color', false);
    }

    useEffect(()=>{
        RegisterModalData('Color', setModaldata);
        RegisterModalObserver('Color', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all PopUpColor">
                    <div className="InputColor">

                        <div className="">

                        </div>

                        <div className="div_saturation">
                            <Saturation hsva={ color.hsv } onChange={ (newColor) => { AltColorHsv(newColor); }}/>
                        </div>  
                        <div className="div_hue">
                            <HuePicker color={ color } onChange={ (color)=>{ setColor(color); setColorHex(color.hex) }} value={ color } />
                        </div>  
                        <div className="div_input">
                            {
                                typeColor == 'Hex' ?
                                <div className="color_hex">
                                    <input type="text" className="input_hex" onChange={ (e)=>{ AltColorHex(e.target.value); }} value={ colorHex } maxLength="7" />
                                    <div className="color_select" style={ { backgroundColor: "rgb(" + color.rgb.r + ", " + color.rgb.g + ", " + color.rgb.b + ")" } } />
                                </div> :
                                <div className="color_rgb">
                                    { typeColor }:
                                    R
                                    <input type="text" className="input_rgb" value={ color.rgb.r } onChange={ (e)=>{ AltColorR(e.target.value); }} />
                                    G
                                    <input type="text" className="input_rgb" value={ color.rgb.g } onChange={ (e)=>{ AltColorG(e.target.value); }} />
                                    B
                                    <input type="text" className="input_rgb" value={ color.rgb.b } onChange={ (e)=>{ AltColorB(e.target.value); }} />
                                </div>
                            }
                        </div> 
                        <div className="div_select_type">
                            <div className="type">
                                { typeColor }
                            </div>
                            <div onClick={ ()=>{ SelectTypeColor(typeColor); } }>
                                <Svg_ArrowRight color="#FFFFFF" className="svg_icon" />
                            </div>
                            <div className="color_rgb_div" style={ { backgroundColor: "rgb(" + color.rgb.r + ", " + color.rgb.g + ", " + color.rgb.b + ")" } } />
                        </div>

                        <div className="div_button">
                            <div className="cancel" onClick={ ()=>{ ClosePopUp(); }}>
                                Cancelar
                            </div>
                            <div className="add_color" onClick={ ()=>{ RegisterColor(); }}>
                                Salvar               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
};
