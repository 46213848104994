import { useState, useEffect } from "react";

import './Settings.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { Svg_Delete, Svg_Link } from "services/SvgFile";
import { Reg_Settings } from "services/Register";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

export default function Page_Settings(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('settings'));

    const [ facebook, setFacebook ] = useState(InitialData('facebook'));
    const [ instagram, setInstagram ] = useState(InitialData('instagram'));
    const [ youtube, setYoutube ] = useState(InitialData('youtube'));
    const [ tiktok, setTiktok ] = useState(InitialData('tiktok'));
    const [ linkedin, setLinkedin ] = useState(InitialData('linkedin'));
    
    const [ companyName, setCompanyName ] = useState(InitialData('company_name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ whatsApp, setWhatsApp ] = useState(InitialData('whatsapp'));
    const [ logo, setLogo ] = useState(InitialData('logo'));
    const [ logoStatus, setLogoStatus ] = useState(false);
    
    const [ defaultColor, setDefaultColor ] = useState(InitialData('default_color'));
    const [ buttonColor, setButtonColor ] = useState(InitialData('button_color'));
    const [ titleColor, setTitleColor ] = useState(InitialData('title_color'));
    const [ footerTextColor, setFooterTextColor ] = useState(InitialData('footer_text_color'));

    const [ listBtn, setListBtn ] = useState(InitialData('list_btn'));
    
    const [ about, setAbout ] = useState(InitialData('about'));
    const [ privacyPolicy, setPrivacyPolicy ] = useState(InitialData('privacy_policy'));
    const [ regulations, setRegulations ] = useState(InitialData('regulations'));
    const [ consentForm, setConsentForm ] = useState(InitialData('consent_form'));
    const [ security, setSecurity ] = useState(InitialData('security'));
    const [ termsConditions, setTermsConditions ] = useState(InitialData('terms_conditions'));
    const [ benefits, setBenefits ] = useState(InitialData('benefits'));
    const [ cancellationPolicy, setCancellationPolicy ] = useState(InitialData('cancellation_policy'));
    const [ codeEthics, setCodeEthics ] = useState(InitialData('code_ethics'));
    const [ confidentialChannel, setConfidentialChannel ] = useState(InitialData('confidential_channel'));
    const [ scriptMeta, setScriptMeta ] = useState(InitialData('script_meta'));
    const [ scriptBody, setScriptBody ] = useState(InitialData('script_body'));
    const [ htmlHeader, setHtmlHeader ] = useState(InitialData('html_header'));

    function InitialData(type){
        return GetDataPage('settings')[type];
    }

    function ShowPalette(type, setColor, value){
        setColor(value);
    }

    function DeleteBtn(id, index, value){
        if(id == 0){            
            const newData = [...listBtn];
            newData.splice(index, 1);
            setListBtn(newData);
        }else {
            SetModalData('Confirmation', { "origin": "settings", "type" : "delete_btn", "status": 0, "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listBtn];
        if(type === "file"){
            if(value){
                newData[index]['file'] = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file'] = "";
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListBtn(newData);
    }

    function AltOrAddColor(setValue, type){
        SetModalData('Color', { "id": 0, "index": 0, "type": type, "setValue": setValue, "ShowPalette": ShowPalette });
        SetModalState('Color', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_Settings(props.userId, facebook, instagram, youtube, tiktok, linkedin, companyName, email, whatsApp, logo, defaultColor, buttonColor, titleColor, footerTextColor, about, privacyPolicy, regulations, consentForm, security, termsConditions, benefits, cancellationPolicy, codeEthics, confidentialChannel, listBtn, scriptMeta, scriptBody, htmlHeader, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('settings', setDataPage);
        return ()=>{
            UnRegisterDataPage('settings', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('settings'));

        setFacebook(InitialData('facebook'));
        setInstagram(InitialData('instagram'));
        setYoutube(InitialData('youtube'));
        setTiktok(InitialData('tiktok'));
        setLinkedin(InitialData('linkedin'));

        setCompanyName(InitialData('company_name'));
        setEmail(InitialData('email'));
        setWhatsApp(InitialData('whatsapp'));
        setLogo(InitialData('logo'));
        setLogoStatus(false);
        
        setDefaultColor(InitialData('default_color'));
        setButtonColor(InitialData('button_color'));
        setTitleColor(InitialData('title_color'));
        setFooterTextColor(InitialData('footer_text_color'));

        setListBtn(InitialData('list_btn'));

        setAbout(InitialData('about'));
        setPrivacyPolicy(InitialData('privacy_policy'));
        setRegulations(InitialData('regulations'));
        setConsentForm(InitialData('consent_form'));
        setSecurity(InitialData('security'));
        setTermsConditions(InitialData('terms_conditions'));
        setBenefits(InitialData('benefits'));
        setCancellationPolicy(InitialData('cancellation_policy'));
        setCodeEthics(InitialData('code_ethics'));
        setConfidentialChannel(InitialData('confidential_channel'));
        setScriptMeta(InitialData('script_meta'));
        setScriptBody(InitialData('script_body'));
        setHtmlHeader(InitialData('html_header'));
    }, [dataPage]);

    return(
        <form className="div_inf_page div_inf_page_settings show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Configurações gerais" PageClick="" page="" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Informações para contato</div>
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome da empresa" className="" setValue={ setCompanyName } value={ companyName } />
                    
                    <Comp_Input index="not" type="email" name="Email" className="inf_data" setValue={ setEmail } value={ email } />

                    <Comp_Input index="not" type="text" name="WhatsApp" className="whatsApp" setValue={ setWhatsApp } value={ whatsApp } placeholder="(xx) xxxxx-xxxx" />

                    <Comp_InputFile index="not" setValue={ setLogo } value={ logo } setStatus={ setLogoStatus } status={ logoStatus } OpenFile={ props.OpenFile } thumbnail={ logo } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Cores</div>
                </div>
                <div className="show_content_data">
                    <div className="div_color" onClick={ ()=>{ AltOrAddColor(setDefaultColor, 'defaultColor') } }>
                        <div className="">Cor padrão</div>
                        <div className="show_div_color" style={ { backgroundColor: defaultColor } } />                        
                    </div>
                    <div className="div_color" onClick={ ()=>{ AltOrAddColor(setButtonColor, 'buttonColor') } }>
                        <div className="">Cor dos botões</div>
                        <div className="show_div_color" style={ { backgroundColor: buttonColor } } />                        
                    </div>
                    <div className="div_color" onClick={ ()=>{ AltOrAddColor(setTitleColor, 'titleColor') } }>
                        <div className="">Cor dos Títulos</div>
                        <div className="show_div_color" style={ { backgroundColor: titleColor } } />                        
                    </div>
                    <div className="div_color" onClick={ ()=>{ AltOrAddColor(setFooterTextColor, 'footerTextColor') } }>
                        <div className="">Cor dos textos do rodapé</div>
                        <div className="show_div_color" style={ { backgroundColor: footerTextColor } } />                        
                    </div>
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Botões</div>
                    {
                        listBtn.length <= 4 ?
                        <div className="" onClick={ ()=>{ setListBtn([...listBtn, { "id": 0, "title": "", "subtitle": "", "file": "", "status": false }]); } }>Adicionar novo botão</div>
                        : 
                        <div className="">Limite de botões atingida</div>
                    }
                </div>
                <div className="show_content_data">
                    {
                        listBtn.length > 0 ?
                        listBtn.map((elem, index)=>{
                            return(
                                <div className="div_show_btn" key={ index }>                                    
                                    <Comp_Input index={ index } type="text" name="Título" className="" setValue={ HandleData } value={ elem.title } input="title" />
                                    
                                    <Comp_Input index={ index } type="text" name="Subtitle" className="" setValue={ HandleData } value={ elem.subtitle } input="subtitle" />

                                    <Comp_InputFile index={ index } setValue={ HandleData } value={ elem.file } setStatus={ HandleData } status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.file } />
                                    
                                    <div className="" onClick={ ()=>{ DeleteBtn(elem.id, index, elem.title) } } style={ { position: "relative", top: "3px" } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="no_data">Nenhuma informação adicionada...</div>
                    }
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Rede social</div>
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Facebook" className="" setValue={ setFacebook } value={ facebook } />
                    {
                        facebook !='' ?
                            <a className="div_icon" target={ facebook }>
                                <Svg_Link className="icons" color={ colorIcon } />
                            </a>
                        : null
                    }
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Instagram" className="" setValue={ setInstagram } value={ instagram } />
                    {
                        instagram !='' ?
                            <a className="div_icon" target={ instagram }>
                                <Svg_Link className="icons" color={ colorIcon } />
                            </a>
                        : null
                    }
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Youtube" className="" setValue={ setYoutube } value={ youtube } />
                    {
                        youtube !='' ?
                            <a className="div_icon" target={ youtube }>
                                <Svg_Link className="icons" color={ colorIcon } />
                            </a>
                        : null
                    }
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Tiktok" className="" setValue={ setTiktok } value={ tiktok } />
                    {
                        tiktok !='' ?
                            <a className="div_icon" target={ tiktok }>
                                <Svg_Link className="icons" color={ colorIcon } />
                            </a>
                        : null
                    }
                </div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Linkedin" className="" setValue={ setLinkedin } value={ linkedin } />
                    {
                        linkedin !='' ?
                            <a className="div_icon" target={ linkedin }>
                                <Svg_Link className="icons" color={ colorIcon } />
                            </a>
                        : null
                    }
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Sobre a empresa</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setAbout } value={ about } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Política de privacidade</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setPrivacyPolicy } value={ privacyPolicy } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Regulamentos</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setRegulations } value={ regulations } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Termo de consentimento de acordo LGPD</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setConsentForm } value={ consentForm } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Segurança</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setSecurity } value={ security } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Termos e condições</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setTermsConditions } value={ termsConditions } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Vantagens</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setBenefits } value={ benefits } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Política de cancelamento</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setCancellationPolicy } value={ cancellationPolicy } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Código de ética e conduta</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setCodeEthics } value={ codeEthics } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Canal confidencial</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setConfidentialChannel } value={ confidentialChannel } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Script/meta tags no header</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setScriptMeta } value={ scriptMeta } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Script no body</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setScriptBody } value={ scriptBody } />
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Html header</div>
                </div>
                <div className="show_content_data">
                    <Comp_Textarea index="not" name="" className="" setValue={ setHtmlHeader } value={ htmlHeader } />
                </div>
            </div>
        </form>
    )
}