import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Site } from "services/Register";
import { Svg_AddNewData, Svg_Arrow, Svg_Purchase } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Site_Details(props){

    const [ listPage, setListPage ] = useState([
        { "value": "category", "label": "Categorias" },
        { "value": "banner", "label": "Banner" },
        { "value": "product", "label": "Produtos" },
        { "value": "services", "label": "Serviços" },
        { "value": "brands", "label": "Marcas" },
        { "value": "faq", "label": "Faq" }
    ]);

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));

    const [ previewFaq, setPreviewFaq ] = useState(GetDataPage('faq'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ origin_, setOrigin_ ] = useState(InitialData('origin_'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ idBanner_1, setIdBanner_1 ] = useState(InitialData('id_banner_1'));
    const [ idBanner_2, setIdBanner_2 ] = useState(InitialData('id_banner_2'));

    function InitialData(type){
        const newData = GetDataPage('site').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        if(type == 'origin_' || type == 'id_banner_1' || type == 'id_banner_2'){
            return { "value": "", "label": "Selecione uma opção", "file": "", "thumbnail": "" };
        }
        return '';
    }

    function ShowData(){
        const newData = [{ "value": "", "label": "Sem banner", "file": "", "thumbnail": "" }];
        GetDataPage('banner').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name, "file": elem.file, "thumbnail": elem.thumbnail });
            }
        });
        return newData;
    }
    
    function OpenDoubts(id, index, status){
        const newData = [...previewFaq];
        newData[index]['status'] = status;
        setPreviewFaq(newData);

        let divResponse = document.getElementById(id);
        if(divResponse){            
            if(status){
                divResponse.style.maxHeight = (divResponse.scrollHeight + 16) + "px";
                divResponse.style.borderBottom = "1px solid " + colorIcon;
                divResponse.style.padding = "10px";
            }else {
                divResponse.style.maxHeight = null;
                setTimeout(() => {
                    divResponse.style.borderBottom = 'none';
                    divResponse.style.padding = '0';
                }, 460);
            }
        }
    }

    function ShowPreview(){
        switch (origin_['value']) {
            case "category":
                return(
                    <div className="div_preview category">
                        <div className="div_list_data">
                            {
                                GetDataPage('category').map((elem, index)=>{
                                    if(elem.type === "category"){
                                        return(
                                            <div className="div_img" key={ index }>
                                                <img alt={ elem.name } src={ elem.file } className="show_logo" />
                                                <div className="title">{ elem.name }</div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                );

            case "banner":
                return(
                    <div className="div_preview banner">
                        {
                            idBanner_1['value'] != "" && idBanner_2['value'] != "" ?
                            <>
                                <div className="opt_banner" style={ { backgroundImage: 'url("' + GetDataPage('banner').find(item => item.id == idBanner_1['value'])['file'] + '")' } } />
                                <div className="opt_banner" style={ { backgroundImage: 'url("' + GetDataPage('banner').find(item => item.id == idBanner_2['value'])['file'] + '")' } } />
                            </> :
                                idBanner_1['value'] != "" && idBanner_2['value'] == "" ?
                                    <div className="opt_banner only_banner" style={ { backgroundImage: 'url("' + GetDataPage('banner').find(item => item.id == idBanner_1['value'])['file'] + '")' } } /> :
                                    idBanner_1['value'] == "" && idBanner_2['value'] != "" ?
                                        <div className="opt_banner only_banner" style={ { backgroundImage: 'url("' + GetDataPage('banner').find(item => item.id == idBanner_2['value'])['file'] + '")' } } />
                                        : null
                        }
                    </div>
                );

            case "services": case "product":
                return(
                    <div className="div_preview services_product">
                        <div className="div_list_data">
                            {
                                GetDataPage(origin_['value']).map((elem, index)=>{
                                    let img = '';
                                    if(elem.gallery.find(item => item.highlight['value'] == true)){
                                        let showDataImg = elem.gallery.find(item => item.highlight['value'] == true);
                                        img = showDataImg.file;
                                    }

                                    return(
                                        <div className="div_service_or_product" key={ index }>
                                            <div className="div_img" style={ { backgroundImage: 'url("' + img + '")' } } />
                                            <div className="title">
                                                { elem.title }
                                                <div className="subtitle">{ elem.subtitle }</div>
                                            </div>                                            
                                            <div className="div_price">
                                                {
                                                    elem.discount_price === "" || elem.discount_price === "0,00" ?
                                                    <div className="discount_price">
                                                        Valor: R$ <span className="bold">{ elem.price }</span>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="price">
                                                            De: R$ <span className="underline">{ elem.price }</span>
                                                        </div>                                         
                                                        <div className="discount_price" style={ { color: colorIcon } }>
                                                            Por: R$ { elem.discount_price }
                                                        </div>
                                                    </>
                                                }       
                                            </div>
                                            <div className="btn div_details" style={ { borderColor: colorIcon } }>
                                                <Svg_AddNewData className="icons" color={ colorIcon } />
                                                <div className="title_btn">Detalhes</div>
                                            </div>
                                            <div className="btn div_purchase" style={ { backgroundColor: colorIcon } }>
                                                <Svg_Purchase className="icons" color="#ffffff" />
                                                <div className="title_btn">Adicionar</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                );

            case "brands":
                return(
                    <div className="div_preview brands">
                        <div className="div_list_data">
                            {
                                GetDataPage('brands').map((elem, index)=>{
                                    return(
                                        <div className="div_img" key={ index }>
                                            <img alt={ elem.name } src={ elem.logo } className="show_logo" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                );

            case "faq":
                return(
                    <div className="div_preview faq">
                        {
                            previewFaq.map((elem, index)=>{
                                return(
                                    <div className="div_list_data" key={ index }>
                                        <div className="question" style={ { backgroundColor: colorIcon } } onClick={ ()=>{ OpenDoubts("response_" + index, index, !elem.status) } }>
                                            <div className="">{ elem.question }</div>
                                            <div className="">
                                                <Svg_Arrow className={ elem.status ? "icons icons_active" : "icons" } color="#ffffff" />
                                            </div>
                                        </div>
                                        <div className="response" id={ "response_" + index } dangerouslySetInnerHTML={ { __html: elem.response.replaceAll('&#34;', '"') } } />
                                    </div>
                                )
                            })
                        }
                    </div>
                );
        
            default:
                return (
                    <div className="no_data">Selecione um tipo de bloco para visualizar uma prévia</div>
                );
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Site(props.userId, idPage, title, origin_, text, idBanner_1, idBanner_2, ()=>{ props.CallbackSuccess(); }, ()=>{ props.CallbackError() });  
    }

    useEffect(()=>{
        RegisterDataPage('site', setDataPage);
        return ()=>{
            UnRegisterDataPage('site', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'))
        setDataPage(GetDataPage('site'));
        
        setTitle(InitialData('title'));
        setOrigin_(InitialData('origin_'));
        setText(InitialData('text'));
        setIdBanner_1(InitialData('id_banner_1'));
        setIdBanner_2(InitialData('id_banner_2'));

        ShowData();
    }, [dataPage, idPage]);

    useEffect(()=>{
        ShowData();
        ShowPreview();
    }, [origin_]);

    return(
        <form className="div_show_data_site div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do bloco" PageClick={ props.OpenPage } page="site_home" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Dados do bloco</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome do bloco" className="" setValue={ setTitle } value={ title } required={ true } />
                    
                    <Comp_Select index="not" name="Tipo de bloco" className="type_data" opt={ listPage } setValue={ setOrigin_ } value={ origin_ } />
                </div>

                {
                    origin_['value'] === "banner" ?
                        <div className="show_content_data">
                            <Comp_Select index="not" name="Selecione o banner 1" className="" opt={ ShowData() } setValue={ setIdBanner_1 } value={ idBanner_1 } />
                            
                            <Comp_Select index="not" name="Selecione o banner 2 (opcional)" className="" opt={ ShowData() } setValue={ setIdBanner_2 } value={ idBanner_2 } />
                        </div>
                    : null
                }

                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Observação" className="" setValue={ setText } value={ text } /> 
                </div>
            </div>

            <div className="show_data_page">
                <div className="div_opt_data">
                    <div className="">Exemplo do modelo escolhido</div>
                </div>
                <div className="show_content_data">
                    {
                        ShowPreview()
                    }
                </div>
            </div>
        </form>
    )
}