import './Select.css';

import Select, { components } from 'react-select';

export default function Comp_Select(props){
    
    const Option = (props) => (
        <components.Option { ...props } className="div_show_data_select">
            {
                props.data.icon != "" && props.data.icon != undefined ? 
                    <div className="opt_color" style={ { background: props.data.icon } } /> 
                : 
                props.data.thumbnail != "" && props.data.thumbnail != undefined ?   
                    <div className="opt_thumbnail" style={ { backgroundImage: 'url("' + props.data.thumbnail + '")' } } />
                    : null
            }            
            {
                props.data.label 
            }
        </components.Option>
    );

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue { ...props } className="div_show_data_select">
            {
                props.data.icon != "" && props.data.icon != undefined ? 
                    <div className="opt_color" style={ { background: props.data.icon } } /> 
                :
                props.data.thumbnail != "" && props.data.thumbnail != undefined ?   
                    <div className="opt_thumbnail" style={ { backgroundImage: 'url("' + props.data.thumbnail + '")' } } />
                    : null
            }    
            {
                children 
            }
        </components.SingleValue>
    );

    return(
        <div className={ props.className ? "Comp_Select" : "Comp_Select Comp_Select_flex_grow" }>
            <Select className={ props.className } defaultOptions options={ props.opt } onChange={ (e)=> { props.index === "not" ? props.setValue(e) : props.setValue(props.input, props.index, e) } } value={ props.value } placeholder="Selecione uma opção" styles={ { singleValue: (base) => ({ ...base, display: "flex", alignItems: "center" }) }} components={ { Option, SingleValue } } />
            <div className="name">{ props.name }</div>
        </div>
    )
}                                                                      