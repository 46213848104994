import './ReturnPage.css';

import { colorIcon } from 'fixedData';

import { Svg_ArrowRight } from 'services/SvgFile';

export default function Comp_ReturnPage(props){

    return(        
        <div className="div_return" onClick={ ()=>{ props.PageClick(props.page, props.idPage) } }>
            <Svg_ArrowRight className="icons" color={ colorIcon } />
            <div className="">Voltar</div>
        </div>
    )
}