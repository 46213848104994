import { useState, useEffect } from "react";

import './Schedule.css';

import Comp_Topic from "components/Topic";

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "moment/locale/pt-br";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { colorIcon } from "fixedData";

import { Svg_Eye } from "services/SvgFile";

export default function Page_Schedule(props){

    const localizer = momentLocalizer(moment);

    const [ showData, setShowData ] = useState(GetDataPage('schedule'));
    const [ listData, setListData ] = useState(ShowDataSchedule());
    const [ typeOpt, setTypeOpt ] = useState('all');

    const dateAtual = new Date();
    const views = { month: true, week: false, day: false, agenda: false }
    const defaultMessages = {
        date: "Data",
        time: "Hora",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Eventos",
        day: "Dia",
        month: "Mês",
        previous: "Anterior",
        next: "Próximo",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",
        noEventsInRange: "Não há tarefas nesse período.",
        showMore: function showMore(total) {
            return "+" + total + " tarefas";
        }
    };

    function ShowDataSchedule(){
        const newData = [...GetDataPage('schedule')];
        newData.map((elem, index)=>{
            elem.start = new Date(elem.start);
            elem.end = new Date(elem.end);
        });
        return newData;
    }

    function ShowHideOpt(type){
        const newData = [...listData];
        newData.map((elem, index)=>{            
            if(type === "all"){
                elem.status['show_or_hide'] = 1;

            }else if(type === "pending"){
                elem.status['show_or_hide'] = 0;
                if(elem.status['value'] == 0){
                    elem.status['show_or_hide'] = 1;
                }

            }else if(type === "finished"){
                elem.status['show_or_hide'] = 0;
                if(elem.status['value'] == 1){
                    elem.status['show_or_hide'] = 1;
                }

            }else if(type === "canceled"){
                elem.status['show_or_hide'] = 0;
                if(elem.status['value'] == 2){
                    elem.status['show_or_hide'] = 1;
                }
            }
        });
        setTypeOpt(type);
        setListData(newData);
    }

    function NewData(){
        SetModalData('Schedule', { "id": 0, "id_given": { "value": 0, "label": "Selecione uma opção" }, "status": { "value": 0, "label": "Pendente" }, "title": "", "subtitle": "", "text": "", "price": "", "start": "", "start_br": "", "hour": "00:00:00" });
        SetModalState('Schedule', true);
    }
    
    function NewSchedule(elem){
        let newDateYear = elem.start.getUTCFullYear();
        let newDateMonth = elem.start.getUTCMonth() + 1;
        newDateMonth = newDateMonth > 9 ? newDateMonth : "0" + newDateMonth;
        let newDateDay = elem.start.getUTCDate();
        newDateDay = newDateDay > 9 ? newDateDay : "0" + newDateDay;
        const newDate = newDateYear + '-' + newDateMonth  + '-' + newDateDay;
        const newDateBr = newDateDay + '/' + newDateMonth  + '/' + newDateYear;

        SetModalData('Schedule', { "id": 0, "id_given": { "value": 0, "label": "Selecione uma opção" }, "status": { "value": 0, "label": "Pendente" }, "title": "", "subtitle": "", "price": "", "text": "", "start": newDate, "start_br": newDateBr, "hour": "00:00:00" });
        SetModalState('Schedule', true);
    }

    function DetailsSchedule(elem){
        SetModalData('Schedule', { "id": elem.id, "id_given": elem.id_given, "status": elem.status, "title": elem.title, "subtitle": elem.subtitle, "price": elem.price, "text": elem.text, "start": elem.start_only, "start_br": "", "hour": elem.hour });
        SetModalState('Schedule', true);
    }

    useEffect(()=>{
        RegisterDataPage('schedule', setShowData);
        return ()=>{
            UnRegisterDataPage('schedule', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('schedule'));
        setListData(ShowDataSchedule());
    }, [showData]);
    
    return(
        <div className="div_inf_page Page_Schedule">
            <Comp_Topic type={ true } icon="add" SearchInput="no_return" search="" title="Agenda" PageClick={ NewData } page="" idPage={ 0 } />
            
            <div className="div_show_calendar">
                <div className="legend">
                    <div className="">Legenda:</div>
                    <div className="div_opt div_all" style={ { borderColor: colorIcon, color: colorIcon } } onClick={ ()=>{ ShowHideOpt('all') } }>
                        {
                            typeOpt === "all" ?
                                <div className="div_eye">
                                    <Svg_Eye className="icons" color={ colorIcon } />
                                </div>
                            : null
                        }
                        Mostrar tudo
                    </div>
                    <div className="div_opt" style={ { background: colorIcon } } onClick={ ()=>{ ShowHideOpt('pending') } }>
                        {
                            typeOpt === "pending" ?
                                <div className="div_eye">
                                    <Svg_Eye className="icons" color={ colorIcon } />
                                </div>
                            : null
                        }
                        Pendente
                    </div>
                    <div className="div_opt" style={ { background: '#3aa856' } } onClick={ ()=>{ ShowHideOpt('finished') } }>
                        {
                            typeOpt === "finished" ?
                                <div className="div_eye">
                                    <Svg_Eye className="icons" color={ colorIcon } />
                                </div>
                            : null
                        }
                        Finalizado
                    </div>
                    <div className="div_opt" style={ { background: '#f00000' } } onClick={ ()=>{ ShowHideOpt('canceled') } }>
                        {
                            typeOpt === "canceled" ?
                                <div className="div_eye">
                                    <Svg_Eye className="icons" color={ colorIcon } />
                                </div>
                            : null
                        }
                        Cancelado
                    </div>                    
                </div>
                <div className="show_data_page div_calendar">
                    <Calendar
                        views={ views }
                        messages={ defaultMessages }
                        localizer={ localizer }
                        events={ listData }
                        showAllEvents={ true }
                        
                        eventPropGetter={(listData) => {
                            if(listData.status['value'] === 1){
                                let backgroundColor = '#3aa856';
                                let display = 'inherit';
                                if(listData.status['show_or_hide'] === 0){
                                    display = 'none';
                                }
                                return { style: { backgroundColor, display } }

                            }else if(listData.status['value'] === 2){
                                let backgroundColor = '#f00000'; 
                                let display = 'inherit';
                                if(listData.status['show_or_hide'] === 0){
                                    display = 'none';
                                }
                                return { style: { backgroundColor, display } }

                            }else {
                                let backgroundColor = colorIcon;
                                let display = 'inherit';
                                if(listData.status['show_or_hide'] === 0){
                                    display = 'none';
                                }
                                return { style: { backgroundColor, display } }
                            }
                        }}
                        
                        selectable={ true }
                        onSelectSlot={ NewSchedule }
                        onSelectEvent={ DetailsSchedule }
                    />
                </div>
            </div>
        </div>
    )
}