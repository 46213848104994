import { useState, useEffect } from "react";

import './Category.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Comp_Topic from "components/Topic";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Edit, Svg_Img } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Category(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('category'));
    const [ listCategory, setListCategory ] = useState(InitialData());
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listCategory.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listCategory.slice(startItens, endItens);

    function InitialData(){
        const newData = GetDataPage('category').filter(item => item.type == "category");
        if(newData){
            return newData;
        }
        return [];
    }
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('category').forEach(item =>{
                if(item.type === "category"){
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListCategory(duplicate);
        }else {
            setListCategory(InitialData());
        }
        setSearch(value);
    }

    function AltAccess(id, value){
        SetModalData('Confirmation', { "origin": "category", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function NewData(title, id){
        let file = '';
        if(id != 0){
            const showData = GetDataPage('category').find(item => item.id == id);
            file = showData['file'];
        }

        SetModalData('Category', { "id" : id, "title": title, "file": file });
        SetModalState('Category', true);
    }

    useEffect(()=>{
        RegisterDataPage('category', setShowData);
        return ()=>{
            UnRegisterDataPage('category', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('category'));
        setListCategory(InitialData());
    }, [showData]);

    return(
        <div className="div_inf_page">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Categorias" PageClick={ NewData } page="" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="60" align="center">File</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td align="center" style={ { display: "flex", alignItems: "center", justifyContent: "center" } }>
                                            {
                                                elem.file != "" ? 
                                                <img alt={ elem.name } src={ elem.file } className="icons_file" onClick={ ()=>{ props.OpenFile('img', elem.file) } } /> :
                                                <Svg_Img className="icons" color={ colorIcon } />
                                            }
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ AltAccess(elem.id, elem.name) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar categoria" data-tooltip-place="top">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>  

                                                <div onClick={ ()=>{ NewData(elem.name, elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="editar categoria" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listCategory.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}