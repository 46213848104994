import { useState, useEffect } from "react";

import './Site.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Comp_Topic from "components/Topic";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

import { Svg_Arrow, Svg_Delete, Svg_Edit } from "services/SvgFile";
import { Reg_SiteAltOrder } from "services/Register";

export default function Page_Site(props){

    const [ search, setSearch ] = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('site'));
    const [ listData, setListData ] = useState(GetDataPage('site'));
    
    const [ itensPage, setItensPage ] = useState(25);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('site').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.origin_.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('site'));
        }
        setSearch(value);
    }

    function AltOrder(id, type, order){
        props.setLoading(true);
        Reg_SiteAltOrder(props.userId, id, type, order, ()=>{ props.CallbackSuccess(); }, ()=>{ props.CallbackError() }); 
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "site", "type" : "delete_data", "status": 0, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('site', setShowData);
        return ()=>{
            UnRegisterDataPage('site', setShowData);
        };
    }, []);

    useEffect(()=>{
        setShowData(GetDataPage('site'));
        setListData(GetDataPage('site'));
    }, [showData]);

    return(
        <div className="div_inf_page div_inf_page_site">   
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Site" PageClick={ props.OpenPage } page="site_home_details" idPage={ 0 } />

            <div className="list_data_table">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="120">Origem</th>
                            <th width="40">Ordem</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ elem.origin_['label'] }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    currentItens.length > 1 ?
                                                        <>                                                        
                                                            {
                                                                (index + 1) == currentItens.length ?
                                                                <div className="div_icon" />
                                                                :
                                                                <div className="div_icon" onClick={ ()=>{ AltOrder(elem.id, "down", elem.order_) } }>
                                                                    <Svg_Arrow className="icons bottom" color={ colorIcon } />
                                                                </div>
                                                            }
                                                            {
                                                                index == 0 ? 
                                                                <div className="div_icon" />
                                                                :
                                                                <div className="div_icon" onClick={ ()=>{ AltOrder(elem.id, "up", elem.order_) } }>
                                                                    <Svg_Arrow className="icons top" color={ colorIcon } />
                                                                </div>
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(elem.id, elem.title) } } data-tooltip-id="delete_inf" data-tooltip-content="Deletar bloco" data-tooltip-place="top">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ props.OpenPage('site_home_details', elem.id) } } data-tooltip-id="delete_inf" data-tooltip-content="Editar bloco" data-tooltip-place="top">
                                                    <Svg_Edit color={ colorIcon } className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 5 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <Tooltip id="delete_inf" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}