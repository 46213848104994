import React, { useState, useEffect } from "react";

import './AddOrEditImg.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_File } from "services/Register";
import { Svg_Close, Svg_Delete } from "services/SvgFile";

import Comp_Input from "components/Input";

export default function PopUP_AddOrEditImg(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ id, setId ] = useState(0);
    const [ name, setName ] = useState('');
    const [ file, setFile ] = useState('');
    const [ status, setStatus ] = useState(false);
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_File(modalData.origin, id, name, file, ()=>{ props.CallbackSuccess(); ClosePopUp(); }, ()=>{ props.CallbackError() });        
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function ClosePopUp(){
        setId(0);
        setName('');
        setFile('');
        setStatus(false);
        SetModalState('AddOrEditImg', false);
    }

    useEffect(()=>{
        RegisterModalData('AddOrEditImg', setModaldata);
        RegisterModalObserver('AddOrEditImg', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setId(modalData.id);
            setName(modalData.name);
            setFile(modalData.file);
            setStatus(false);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form onSubmit={ SaveData }>
                <div className="PopUp PopUpCenter">
                    <div className="all AddOrEditImg">
                        <div className="div_data type_div">
                            <div className="title">
                                Informações do arquivo
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <span className="div_input div_add_img">
                                        <div className={ status ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { status ? "Arquivo adicionada" : "Adicionar arquivo" }
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                    <Svg_Delete className="icons" color="#ffffff" />
                                                </div>
                                                <div className="show_img_" onClick={ ()=>{ props.OpenFile('img', status ? URL.createObjectURL(file) : file) } }>
                                                    {
                                                        status ?
                                                        <img alt="logo" src={ URL.createObjectURL(file) } className="file_icon" />
                                                        :
                                                        <img alt="logo" src={ file } className="file_icon" />
                                                    }
                                                </div>
                                            </>
                                            : null
                                        }
                                    </span>
                                    {
                                        modalData.origin == "brands" ? "Medida sugerida 214px por 120px" :
                                            modalData.origin == "banner" ? 
                                            "Medida sugerida 1436px por 560px" : "Medida sugerida 1436px por 528px"
                                    }
                                </div> 
                                <div className="list_input_data">
                                    <Comp_Input index="not" type="text" name="Nome do arquivo" className="" setValue={ setName } value={ name } />
                                </div> 
                                <div className="div_btn">
                                    <button type="submit" className="btn_save">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
